.socialShare {
    display: flex;
    align-items: center;
    padding: 4px 0px;
    margin-bottom: 13px;
    margin-top: 10px;
}

.socialShare img {
    width: 45px;
    margin-right: 15px;
}

.mapDetails {
    background-color: #F1F1F1;
    display: flex;
    align-items: center;
    padding: 24px 15px;
}

.mapDetails h5 {
    margin-top: 0px !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 20px !important;
    color: #000000 !important;
}

.mapDetails p {
    font-weight: 400 !important;
    font-size: 14.3px !important;
    line-height: 17px !important;
    color: #000000;
}

.mapDetails img {
    width: 36px;
    margin: 0px 20px 0px 18px;
}

.mapLocationSet {
    background-color: #F1F1F1;
    align-items: center;
    padding: 12px 15px;
}

.mapLocationSet h1 {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 17px;
}

.mapLocationSet h5 {
    margin-top: 0px !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 20px !important;
    color: #000000 !important;
}

.mapLocationSet p {
    font-weight: 400 !important;
    font-size: 14.3px !important;
    line-height: 17px !important;
    /* line-break: unset; */
}

.noteBoxClass p {
    font-size: 19px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    background: #000000;
    border-radius: 14px;
    padding: 28px 29px;
    font-weight: 400;
}

.noteBoxClass h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 25px;
    text-align: center;
    color: #474747;
}

/* =====Responsive====== */

@media screen and (max-width: 340px) {
    .socialShare img {
        width: 42px !important;
        margin-right: 15px;
    }
}

.mapBoxCls .map-container {
    width: 100% !important;
    height: 221px !important;
    margin-top: 10px !important;
}


@media screen and (min-width: 320px) and (max-width: 575px) {
    .noteBoxClass h3 {
        font-size: 28px;
    }

    .noteBoxClass p {
        font-size: 17px;
    }
}