.searchBar {
    display: flex;
    margin-top: 15px;
}

.searchBar .form-control {
    border: none !important;
    box-shadow: none !important;
    font-weight: 400;
    background: #FAFAFA;
    border-radius: 4px;
    height: 40px;
}

.searchBar .form-control:focus {
    border: none !important;
}

.searchBar svg {
    color: #FFFFFF;
    font-weight: 700;
    background: #5CB242;
    padding: 6px;
    width: 39px;
    height: 39px;
    margin-left: 6px;
    border-radius: 4px;
    cursor: pointer;
}

.searchedComponent .latestDeals {
    margin-top: 23px !important;
    margin-bottom: 0px !important;
}

.searchedComponent .CompanyData_productslist__S3jUx {
    margin-top: 23px !important;
    margin-bottom: 0px !important;
}

.searchedComponent .classiFieds_forSaleBox {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.searchedComponent .main {
    margin-bottom: 0px !important;
}

.backBtnSearch {
    align-items: baseline !important;
}

.searchModuleBox {
    margin-bottom: 40px;
}

.searchModuleBox .marginTop {
    margin-top: 30px;
}

@media only screen and (min-width: 1200px) {
    .searchBar {
        justify-content: flex-end;
    }
}

.cusinput {
    width: 100%;
    max-width: 400px;
}

@media only screen and (max-width: 991px) {
    .searchBar {
        width: 100%;
        max-width: 400px;
    }

    .searchBar .form-control {
        border: none !important;
        box-shadow: none !important;
        font-weight: 400;
        background: #FAFAFA;
        border-radius: 4px;
        height: 40px;
    }
}

.searchHide svg {
    color: #FFFFFF;
    font-weight: 700;
    background: #5CB242;
    padding: 6px;
    width: 39px;
    height: 39px;
    border-radius: 4px;
    cursor: pointer;
}

@media only screen and (max-width: 991px) {
    .searchTab {
        display: none !important;
    }
}

@media only screen and (min-width: 992px) {
    .searchInput {
        display: none !important;
    }

    .searchHide {
        display: none !important;
    }

    .columnRight {
        padding-right: 0px !important;
    }
}

@media only screen and (max-width: 376px) {
    .columnManage {
        padding: 0px !important;
    }
}
