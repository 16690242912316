.passwordSent p {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #656565;
    margin-bottom: 0px;
}

.passwordSent span {
    font-weight: 600;
    font-size: 14px;
    color: #656565;
}

.forgotPassword h4 {
    font-weight: 700;
    font-size: 14px;
    color: #242424;
    margin-top: 20px;
    cursor: pointer;
}