.countryIcon .imgIcon img {
  margin-right: 10px !important;
  margin-bottom: 4px !important;
  cursor: pointer;
}

.countryText {
  margin-top: -1px !important;
  color: #4b4b4b;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  cursor: pointer;
}

span.countryText {
  margin: 8px;
  font-size: 15px;
  font-weight: 400;
  color: #4b4b4b;
  font-family: "Nunito";
}

.countryText span {
  color: #5CB242;
  font-size: 15px;
  font-weight: 400;
  margin-left: 5px;
  font-style: normal !important;
  font-family: "Nunito";
  line-height: 32px;
}

.ClassifiedCountry_imgIcon__pmMlC {
  margin-top: -3px;
}

.countryText {
  display: flex;
}

.countryIcon {
  display: flex !important;
  margin-top: 15px !important;
  padding: 5px !important;
}
