.companyDetails h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    color: #000000;
    letter-spacing: unset;
}

.companyDetails p {
    font-family: 'Nunito Sans';
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.39px;
    color: #000000;
}

.companyDetails h5 {
    font-weight: 600;
    font-size: 20.8px;
    line-height: 29px;
    margin-bottom: 0px;
    margin-top: 25px;
}

.companyDetails li {
    font-weight: 400;
    font-size: 19.8px;
    line-height: 29px;
    list-style: none;
}

.companyDetails li::before {
    content: "";
    margin-right: 0.5rem;
    width: 13px;
    height: 13px;
    border: 4px solid #fa6400;
    border-radius: 50%;
    display: inline-grid;
    place-items: center;
    vertical-align: middle;
}

.brandType {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.brandType p {
    background: #828282 !important;
    border-radius: 23.52px;
    color: #FFFFFF;
    padding: 5px 15px;
    margin-right: 9px;
    margin-top: 10px;
    text-decoration: none;
    font-weight: 600;
    font-size: 18.2px;
    line-height: 27px;
    text-align: center;
    font-family: 'Poppins';
}

.playBox {
    width: 100% !important;
    height: 276.43px !important;
    margin: 20px 0px;
}

.mapAccordion {
    margin-top: 21px;
}

.mapAccordion .card {
    border: none;
}


.btn-link:hover {
    color: #000000;
    text-decoration: none;
}

.btn-link:focus {
    color: #000000;
    text-decoration: none;
}

.mapAccordion h2 button {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #000000;
    border: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f1f1f1 !important;
}

.mapAccordion .card-body {
    padding: 0px;
}

.mapAccordion .card-body .mapBox {
    margin-top: 0px !important;
}

.accordion>.card>.card-header {
    background-color: #F1F1F1 !important;
    border-bottom: none !important;
}

.advertBox {
    background: #FFFFFF;
    border-radius: 7.89238px;
    margin-top: 10px;
}

.companyDetails .watsappCls img {
    width: unset !important;
}

.companyDetails .watsappCls span {
    font-weight: 500 !important;
    font-size: 19.2px !important;
    line-height: 21px !important;
}

.advertBox img {
    width: 100%;
    border-radius: 7.89238px;
    object-fit: cover;
}

.latestDeals .watsappCls {
    margin-bottom: 10px;
}

.latestDeals .watsappCls svg {
    height: 28px;
    width: 28px;
    margin-top: 0px;
}

.latestDeals .watsappCls span {
    font-weight: 500;
    font-size: 19.2px !important;
    line-height: 21px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    letter-spacing: -0.32px;
    color: #FFFFFF;
}

.dealDetailsList {
    margin-top: 10px;
}

@media screen and (min-width: 320px) and (max-width: 400px) {
    .brandType p {
        padding: 5px 13px !important;
        font-weight: 600;
        font-size: 16px !important;
    }
}


.companyDetails .dealText p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.61875px;
    color: #000000;
}

.companyDetails .websiteUrl p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.61875px;
    color: #000000;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .playBox {
        width: 100% !important;
        height: 360px !important;
    }
}