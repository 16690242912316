.topHeader {
  height: 52px;
  background-color: #5cb242;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  z-index: 999;
  position: fixed !important;
  top: 0;
}

.headerContent {
  position: relative;
}

.headerContent .navbar-nav .nav-link {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  /* font-family: Poppins; */
}

.headerContent .navbar-nav .nav-link:hover {
  color: #ffffff;
}

.headerContent .navbar-nav .nav-link.active {
  color: #ffffff;
}

.headerContent .navbar-nav .dropdown {
  position: absolute;
  right: 120px;
}

.headerContent .navbar-nav .dropdown svg {
  font-size: 20px;
  margin-bottom: 5px;
}

.headerContent .navbar-nav .dropdown .dropdown-toggle::after {
  display: none;
}

.topHeaderWrapper .logoImg {
  text-align: center !important;
  margin: 30px 0px;
  margin-top: 82px;
  transition: ease-in-out;
}

.topHeaderWrapper .logoImg img {
  width: 100%;
  max-width: 600px;
}

.headerContent .navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #ffffff;
}
.topHeaderWrapper .logoImgMargin {
  margin-top: 286px;
}

.headerContent .navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #ffffff;
}

@media screen and (max-width: 991px) {
  /* .topHeader.movedHeader{
    margin-top: 231px;
  }
  .topHeader.movedHeader .navbar-collapse.show{
    margin-top: -314px;
  } */
  .navbar > .container {
    justify-content: center !important;
    width: 100%;
    margin: 0;
    max-width: 991px;
  }
  .topHeader {
    background-color: lightgray;
  }
  .topHeader .navbar-toggler {
    border: none !important;
  }
  .topHeader .navbar-toggler:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  .topHeader .navbar-collapse {
    background-color: #5cb242;
    margin-top: 11px;
  }
  .topHeader .navbar-nav {
    justify-content: center;
    align-items: center;
  }

  .topHeaderWrapper .logoImg img {
    width: 100%;
    max-width: 550px;
  }
  .topHeaderWrapper .logoImgMargin {
    text-align: center !important;
    margin-bottom: 30px;
    margin-top: 310px;
    transition: ease-in-out;
  }
  .topHeaderWrapper .logoImgMargin img {
    width: 100%;
    max-width: 550px;
  }
  .topHeader .navbar-toggler-icon {
    width: 2em;
  }
  .topHeader .navbar-light .navbar-toggler {
    font-size: 18px;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    color: #000000;
  }
  .topHeader .navbar-light .navbar-toggler img {
    width: 25px;
    margin-left: 6px;
  }
}
