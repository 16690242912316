.forgotPassword {
    text-align: center;
}

.forgotPassword img {
    width: 100%;
    max-width: 100px;
}

.forgotPassword h1 {
    font-size: 22px;
    color: #000000;
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
}

.forgotPassword p {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #656565;
}