.bookmarkHeading {
    margin-top: 33px;
}

.bookmarkHeading h3 {
    background: #000000;
    border-radius: 10px;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
    padding: 16px;
}

.clearAll {
    margin-bottom: 0px;
    margin-top: 20px;
    text-align: end;
}

.clearAll span {
    color: #e74721;
    font-weight: 600;
    cursor: pointer;
}


@media only screen and (max-width: 991px) {
    .bookmarkHeading {
        margin-top: 10px;
    }
}