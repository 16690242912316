.latestDeals {
    background: #FFFFFF;
    box-shadow: 0px 25px 40px rgb(0 0 0 / 3%);
    border-radius: 6px;
    margin-top: 10px;
    padding: 30px;
    margin-bottom: 30px;
}

.latestDeals img {
    width: 100%;
}

.latestDeals h3 {
    font-size: 25.2px;
    font-weight: 600;
    color: black;
    line-height: 36px;
    margin-bottom: 0px;
    padding: 12px 0px;
    letter-spacing: -0.315px;
}

.latestDeals p {
    color: #000000;
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
    margin-bottom: 0px;
    letter-spacing: -0.36px;
    font-family: 'Nunito Sans';
}

.dealSubText {
    margin-bottom: 16px !important;
}

.detailsValue {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
}

.latestDeals button {
    display: block;
    width: 100%;
    height: 46px;
    border: none;
    border-radius: 7.2px;
    font-weight: 600;
    font-size: 19.2px;
    line-height: 29px;
    letter-spacing: -0.654545px;
    color: #FFFFFF;
}

.detailsValue img {
    width: 100%;
    max-width: 42px;
    margin-right: 20px;
}

.dealText {
    margin-bottom: 5px;
}

.dealText p {
    line-break: anywhere;
    line-height: 20px;
    font-size: 18px;
    color: #000000;
    letter-spacing: -0.61875px;
    font-weight: 400;
    font-family: 'Poppins';
}

.dealText span {
    font-size: 11px;
    color: #828282;
    font-weight: 500;
    line-height: 16px;
}

.dealPrice {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
}

.dealPrice h4 {
    font-weight: 400;
    font-size: 33.6px;
    line-height: 50px;
    margin-bottom: 0px !important;
    margin-right: 5px;
}

.dealPrice span {
    font-weight: 300;
    font-size: 12px;
    color: #000000;
    line-height: 18px;
}

.whatsApp {
    margin-bottom: 10px;
    background: linear-gradient(180deg, #85D51D 0%, #6AAE1B 98.67%);
    color: #FFFFFF;
    font-weight: 500;
    font-size: 19.2px;
    line-height: 29px;
}

.viewProfile {
    font-weight: 600;
    font-size: 19.2px;
    line-height: 29px;
    color: #FFFFFF;
    background: #000000;
}

button.whatsApp svg {
    margin-right: 6px;
}

.dealContainer {
    padding-bottom: 15px;
    margin-top: 23px;
}

.websiteUrl p {
    line-break: anywhere;
    line-height: 20px;
    font-size: 18px;
    color: #000000;
    letter-spacing: -0.61875px;
    font-weight: 400;
    font-family: 'Poppins';
}

.stickyClass {
    position: sticky;
    top: 0px;
    bottom: 0px;
}

@media screen and (max-width: 400px) {
    .latestDeals {
        padding: 15px;
    }
}

.companyDetails .companyLogo img {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 4px 4px 10px rgb(0 0 0 / 20%);
    border-radius: 7.8px;
    margin-bottom: 15px;
}

.displayNoText {
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.315px;
    color: #000000;
    margin-top: 15px;
    margin-bottom: 100px;
}

.websiteUrl a {
    text-decoration: none !important;
    color: #000000;
}

.dealText a {
    text-decoration: none !important;
    color: #000000;
}

@media screen and (min-width: 320px) and (max-width: 400px) {
    .websiteUrl p {
        font-size: 14px !important;
    }

    .dealText p {
        font-size: 14px !important;
    }

    .dealPrice h4 {
        font-size: 28px !important;
    }
}

/* search deal text css */

.latestDeals .searchDealText {
    font-weight: 600;
    color: #fff;
    border-radius: 14px;
    background: linear-gradient(180deg, #f24237 0%, #ea2064 100%);
    width: 100%;
    max-width: 95px;
    height: 30px;
    text-align: center;
    padding-top: 6px;
    margin-top: 15px;
    font-size: 13px;
    line-height: 20px;
}

.companyImgPosition {
    position: relative;
    cursor: pointer;
}

.companyImgPosition svg {
    position: absolute;
    right: 8px;
    top: 14px;
    width: 25px;
    padding: 1px 2px;
    height: 25px;
    background: #E91F63;
    border-radius: 5px;
    color: white;
}