.alertBox {
    border: 3px solid #5CB242;
    background: #FFFFFF;
    border: 3px solid #5CB242;
    box-shadow: 5px 5px 10px rgba(165, 165, 165, 0.5);
    border-radius: 8px;
    width: 100%;
    max-width: 450px;
    margin-top: -16px;
    margin-bottom: 20px;
  }

  .alert-heading.h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    text-align: center;
    color: #5CB242;
     margin-left: 25px; 
}

.alertBox p {
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 22px !important;
    text-align: center !important;
    color: #000000 !important;
    margin-left: 30px !important; 
    margin-bottom: 0px;


}


.alertBox .btn-close {
  color: #000000 !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  padding: 6px 5px !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  opacity: unset;
  cursor: pointer;

  
}

.alertBox .alert {
  
  --bs-alert-margin-bottom: 0px !important;
  
}

.alertBox .btn-close:focus{
  box-shadow: unset !important;
}