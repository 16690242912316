.headerSection {
  padding-bottom: 12px;
  border-bottom: 1px solid #b7b7b7;
}

.logoImg {
  text-align: center !important;
  margin: 30px 0px;
}

.logoImg img {
  width: 100%;
  max-width: 430px;
}

.navItem {
  display: flex;
}

.headerSection .navbar {
  display: unset !important;
}

.navItem .navbar-toggler:focus {
  box-shadow: none !important;
}

.navItem .navbar-nav .nav-link {
  background: #ffffff !important;
  margin-top: 15px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #000000;
  padding-right: 29px !important;
  padding-left: 29px !important;
  opacity: 0.5;
}

.navItem .navbar-nav .nav-link.active {
  background: #ffffff !important;
  color: #000000;
  opacity: unset !important;
}

.ulValue {
  gap: 15px;
  flex-wrap: wrap;
}

.ulValue a {
  margin-top: 0px !important;
}

.headerFeature {
  margin-top: 0px !important;
}

.headerLinks {
  flex-wrap: wrap !important;
  gap: 15px;
}

@media only screen and (max-width: 991px) {
  .navItem {
    display: block;
  }

  .headerFeature {
    margin-top: 15px !important;
  }

  .ulValue {
    margin-top: 15px !important;
  }
}

/* @media only screen and (max-width: 991px) {
  .navItem .navbar-nav .nav-link {
    width: 100%;
    max-width: 160px;
  }
} */

/* deals header and serach bar css manage */
.dealHeader {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .dealHeader {
    display: block !important;
  }
}

/* banner img css for classified  */

.bannerImg {
  text-align: center;
  margin-bottom: 10px;
}

.bannerImg iframe {
  width: 100%;
  height: 140px;
  width: 1px;
  min-width: 100%;
  width: 100%;
  height: 100%;
  border: 0;
  scroll-behavior: unset;
}

/* back arrow button css  */
.backarrowButton svg {
  background: #232323;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: 19px;
  cursor: pointer;
  margin-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navItem .navbar-nav .nav-link {
    padding-right: 11px !important;
    padding-left: 11px !important;
  }
}

.headerLinks .active .nav-link {
  background: #ffffff !important;
  color: #000000;
  opacity: unset !important;
}

@media screen and (min-width: 1200px) {
  .header .container {
    max-width: 1100px;
  }

  .header .headerSection {
    width: 1100px;
  }

  .screenOverride {
    max-width: 1100px;
  }

  .screenOverride .row {
    justify-content: space-between;
  }

  .screenOverride .col-lg-6 {
    width: 530px;
  }

  .screenOverride .col-lg-5 {
    width: 530px;
  }

  .screenOverride .noteBoxClass {
    width: 530px;
  }

  .screenOverride .stickyClass {
    width: 530px;
  }

  .screenOverride .tab-content {
    width: 530px;
  }

  .screenOverride .Bookmarks_bookmarkHeading__FsPeU {
    width: 530px;
  }

  .screenOverride .classiFieds_forSaleBox {
    width: 530px;
  }

  .screenOverride .categoryButton {
    width: 530px;
  }

  .screenOverride .advertisment {
    width: 530px;
  }

  .screenOverride .profileRightPart {
    width: 530px;
  }

  .screenOverride .latestDeals {
    width: 530px;
  }

  .screenOverride .companyDetails {
    width: 530px;
  }

  /* .screenOverride .displayNoText {
    width: 530px;
  } */

  .screenOverride .postAdvertBox {
    width: 530px;
  }

  .screenOverride .advertBox {
    width: 530px;
  }

  .screenOverride .post_Add_CategoryButton {
    width: 530px;
  }

  .navItem .navbar-nav .nav-link {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }

  .navHeader .navbar-nav a.nav {
    padding: 10px 16px;
    margin-right: 7px;
  }

  .screenOverride .searchedComponent {
    width: 530px;
  }

  .screenOverride .searchModuleBox .marginTop {
    width: 530px;
  }

  .screenOverride .loadmoreBtn {
    width: 530px;
  }

  .screenOverride .bannerImg {
    width: 1100px;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1199px) {
  .header .container {
    max-width: 1024px;
  }

  .screenOverride {
    max-width: 1024px;
  }
}

@media screen and (min-width: 310px) and (max-width: 991px) {
  .bannerImg {
    margin-bottom: 0px;
  }
}



.navItem1 a.nav-link{
  margin-right: 9px !important;
  text-decoration: none !important;
  max-width: 200px !important;
  background: #ffffff !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  letter-spacing: -0.2px !important;
  color: #000000 !important;
  opacity: 0.5 !important; 
  padding-left: 20px;
  padding-right: 20px;
}


.navItem1 a.nav-link.active{
  background: #ffffff !important;
  color: #000000 !important;
  opacity: unset !important;
}

@media screen and (min-width: 1200px){
  .navItem1 a.nav-link{
    padding-left: 26px;
    padding-right: 26px;
  }
}

