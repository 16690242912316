.main {
    padding-top: 23px;
    margin-bottom: 23px;
}

.signupForm {
    background-color: #fff;
    width: 100%;
    max-width: 430px;
    margin: auto;
    padding: 40px;
    margin-bottom: 50px;
}

.topHeading h1 {
    font-size: 20px;
    color: #000000;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
}

.loginComponents {
    background: #F6F6F6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 20px;
    cursor: pointer;
}

.loginComponents h3 {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-bottom: 3px;
}

.loginComponents img {
    width: 60px;
    height: 60px;
    margin-right: 25px;
}

.accountType {
    margin-top: 20px;
}

.accountType p {
    color: #242424;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
}


.accountType span {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}


/* social signup facebook css */

.loginComponents button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    /* margin-left: -6px; */
    padding: 0px !important;
}

.loginformcomponent button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
}

@media screen and (min-width: 320px) and (max-width: 325px) {
    .accountType p {
        font-size: 13px;
    }

    .accountType span {
        font-size: 13px;
    }
}

@media screen and (min-width: 391px) and (max-width: 398px) {
    .loginComponents h3 {
        font-size: 15px;
    }
}

@media screen and (min-width: 376px) and (max-width: 390px) {
    .loginComponents h3 {
        font-size: 14px;
    }

    .loginComponents img {
        margin-right: 20px;
    }
}

@media screen and (min-width: 351px) and (max-width: 375px) {
    .loginComponents {
        padding: 5px 10px;
    }

    .loginComponents img {
        margin-right: 15px;
    }

    .loginComponents h3 {
        font-size: 14px;
    }
}

@media screen and (min-width: 340px) and (max-width: 350px) {
    .loginComponents {
        padding: 5px 10px;
    }

    .loginComponents img {
        width: 54px;
        height: 54px;
        margin-right: 10px;
    }

    .loginComponents h3 {
        font-size: 14px;
    }
}

@media screen and (min-width: 320px) and (max-width: 339px) {
    .loginComponents {
        padding: 5px 7px;
    }

    .loginComponents img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .loginComponents h3 {
        font-size: 13px;
    }
}

@media screen and (min-width: 320px) and (max-width: 400px) {
    .loginformcomponent button {
        width: 100%;
        min-width: 30px;
    }
}


@media screen and (min-width: 310px) and (max-width: 319px) {
    .loginComponents {
        padding: 5px 7px;
    }

    .loginComponents img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .loginComponents h3 {
        font-size: 12px;
    }
}






 