.forgotPassword {
  text-align: center;
}

.forgotPassword img {
  width: 100%;
  max-width: 100px;
}

.forgotPassword h1 {
  font-size: 20px;
  color: #000000;
  text-align: center;
  font-weight: 500;
  margin-top: 20px;
}

.forgotPassword p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #656565;
  padding: 0px 33px;
}

.passwordSent p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #656565;
  margin-bottom: 0px;
}

.passwordSent span {
  font-weight: 600;
  font-size: 14px;
  color: #656565;
}

.forgotPassword h4 {
  font-weight: 700;
  font-size: 14px;
  color: #242424;
  margin-top: 20px;
  cursor: pointer;
}

.otpbox {
  text-align: center;
  padding: 5px 6px;
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.otpbox input::placeholder {
  font-size: 25px;
  color: #878787;
}

.inputCus input {
  width: 32px !important;
  max-width: 32px;
  height: 37px;
  margin: 16px 0px 16px 27px;
  border-radius: 8px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  outline: none;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.otpbox input {
  background-color: #EAEAEA;
  border: none;
  border-radius: 6px;
  font-size: 23px;
}

.otpbox input:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

@media screen and (min-width: 320px) and (max-width: 402px) {
  .forgotPassword p {
    padding: 0px 0px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 333px) {
  .forgotPassword h1 {
    font-size: 18px;
    font-weight: 500;
  }
}

@media screen and (min-width: 320px) and (max-width: 359px) {
  .inputStyle {
    width: 38px !important;
    height: 38px;
    margin: 0 10px !important;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}

.emailText {
  padding: 0px 52px !important;
}